import React from 'react'
import Popup from '../Component/Popup'
import Header from '../Component/Header'
import HomeSlider from '../Component/HomeSlider'
import TopperXII from '../Component/TopperXII'
import TopperX from '../Component/TopperX' 
import Birthday from '../Component/Birthday' 
import Events from '../Component/Events'
import News from '../Component/News'
import Campuscare from '../Component/Campuscare'
import OnlineRegistration from '../Component/OnlineRegistration'
// import Facilities from'../Component/Facilities'
import Gallery from '../Component/Gallery'
// import Message from '../Component/Message' 
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import Pageloader from '../Component/PageLoader'
const Home = () => {
  return (
    <>
    <Pageloader />
      <Campuscare />
      <Popup /> 
      <Header />
      
      <OnlineRegistration />
      <HomeSlider>
        
      </HomeSlider>

      <div className="aboutmidsec padded-sec">
        <div className='button'>
        <Link to="https://www.lfscampuscare.org/" target="_blank">
          <img src="/Images/online-payment.png" alt="Little Flower School, Gita Vatika" />
          <p>ONLINE<br /> PAYMENT</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="https://cisce.org/" target='_blank'>
            <img src="/Images/csice.png" alt="Little Flower School, Gita Vatika" />
            <p>CISCE</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="/ParentPortal">
          <img src="/Images/portal.png" alt="Little Flower School, Gita Vatika" />
          <p>PORTAL (HELP)?</p></Link>
        </div>
        <div className='phone'>
          <Link to="tel: 0551-2283454">
            <img src="/Images/phone.png" alt="Little Flower School, Gita Vatika" />
          </Link>
        </div>
        <div className='button'>
          <Link to="https://www.lfscampuscare.org/" target="_blank">
            <img src="/Images/alumni.png" alt="Little Flower School, Gita Vatika" />
            <p>ALUMNAE<br /> REGISTRATION</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="/calender">
          <img src="/Images/calender.png" alt="Little Flower School, Gita Vatika" />
          <p>ACADEMIC <br />CALENDAR</p>
          </Link>
        </div>
        <div className='button'>
        <Link to="/feestructure">
          <img src="/Images/fee.png" alt="Little Flower School, Gita Vatika" />
          <p>FEE STRUCTURE (2024-2025)</p>
          </Link>
        </div>
      </div>
      <div className="aboutsec about-bg">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className="col-lg-6 about-bg">
              <div class="about-news">
                <div className='title'>
                  <p>Key updates and school happenings</p>
                  <h2>Latest news at our campus</h2>
                </div>
                <News />

              </div>
            </div>
            <div className="col-lg-6">

            </div>
          </div>
        </div>
      </div>
      <div className='aboutSection'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className="about-img">
                <div className='d-flex'>
                  <img src='/Images/about-school1.jpg' alt="Little Flower School, Gita Vatika" />
                  <img src='/Images/about-school2.jpg' alt="Little Flower School, Gita Vatika" />
                  <img src='/Images/about-school3.jpg' alt="Little Flower School, Gita Vatika" />
                </div>
                <div className='d-flex'>
                  <img src='/Images/about-school7.jpg' alt="Little Flower School, Gita Vatika" />
                  <img src='/Images/about-school.png' className='no-anim' alt="Little Flower School, Gita Vatika" />
                </div>
                <div className='d-flex'>
                  <img src='/Images/about-school5.jpg' alt="Little Flower School, Gita Vatika" />
                  <img src='/Images/about-school6.jpg' alt="Little Flower School, Gita Vatika" />
                  <img src='/Images/about-school4.jpg' alt="Little Flower School, Gita Vatika" />
                </div>
              </div>
            </div>

            <div className='col-lg-6 abt-content-block'>
              <div className='abt-content section-title'>
                <div className='title'>
                  <p>ABOUT US</p>
                  <h2>LITTLE FLOWER SCHOOL</h2>
                </div>
                <p><b>Little Flower School</b> established and administered by the Catholic Diocese of Gorakhpur, is premier English Medium School of Eastern Uttar Pradesh. It was founded on <b>14th July 1984</b> with 110 students in Class VI as the fulfilment of the long cherished dream of the people of Gorakhpur and the response of the Catholic Mission to render quality education in the higher level to the children studying in primary schools. Being governed by the Catholic Church, a minority community the school has the primary objective of educating the children of the community. However, we shall not spare any effort in rendering our service to all people, irrespective of caste, sex, creed or religion, so as to conserve their religion, language and culture. The school aims at the integral development of the personality of every child, to make him committed to the Service of God and of the nation, to instil into him a spirit of compassion, unselfish service, dedication to duty, love for fellowmen and a thirst for excellence and wisdom. It will be our earnest effort to give equal importance to the moral development of the students.</p>
                <button className='button'><Link to="/about">Read More</Link></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event padded-sec">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className="col-lg-12 section-title">
              <div className='title'>
                <p>what's happening at our school</p>
                <h2>events updates</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Events />
              {/* <Message /> */}

            </div>
          </div>
        </div>
      </div>
      <div className="tooper-birthday padded-sec">
        <div className="container-fluid section-title">
          <div className="row">
            <div className="col-lg-4">
              <div className='title'>
                <p>our shining stars</p>
                <h2>X toppers</h2>
                <div className='arrrow-btn'>
                  <Link to="/topperX">
                  <i class="bi bi-chevron-right"></i></Link>
                </div>
              </div>
              <TopperX />

            </div>
            <div className="col-lg-4">
              <div className='title'>
                <p>stars of the day</p>
                <h2>happy birthday</h2> 
                
              </div>
               <Birthday /> 

            </div>
            <div className="col-lg-4">
              <div className='title'>
                <p>our shining stars</p>
                <h2>XII toppers</h2>
                <div className='arrrow-btn'>
                <Link to="/topperXII">
                  <i class="bi bi-chevron-right"></i>
                  </Link>
                </div>
              </div>
              <TopperXII />
            </div>
          </div>
        </div>
      </div>
     
 <div className='management padded-sec'>
  <div class="container-fluid">
    <div className='row'>
      <div className='col-md-12 section-title'>
      <div className='title'>
                <p>stars of the day</p>
                <h2>school management</h2>
              </div>
      </div>
      
    </div>
    <div className='row'>
      <div className='col-lg-3 col-md-6'>
        <div className='management-list'>
          <img src="/Images/management1.png" className='img-fluid' alt="Little Flower School, Gita Vatika" />
          <p>Rt. Rev. Dr. Mathew Nellikunnel CST</p>
          <p><span>patron of the school</span></p>
        </div>
      </div>
      <div className='col-lg-3 col-md-6'>
        <div className='management-list'>
          <img src="/Images/management2.png" className='img-fluid' alt="Little Flower School, Gita Vatika" />
          <p>Rev. Fr Sibichan</p>
          <p><span>Manager</span></p>
        </div>
      </div>
      <div className='col-lg-3 col-md-6'>
        <div className='management-list'>
          <img src="/Images/management3.png" className='img-fluid' alt="Little Flower School, Gita Vatika" />
          <p>Rev. Fr Suresh K V</p>
          <p><span>Principal</span></p>
        </div>
      </div>
      <div className='col-lg-3 col-md-6'>
        <div className='management-list'>
          <img src="/Images/management4.png" className='img-fluid' alt="Little Flower School, Gita Vatika" />
          <p>Rev. Fr Jestin Joseph</p>
          <p><span>vice principal</span></p>
        </div>
      </div>
    </div>
  </div>
 </div>
      <div className="homegallery padded-sec">
        <div className="container-fluid fulwidth">
          <div className="row">
          <div className='col-md-12 section-title'>
      <div className='title'>
                <p>school in the media</p>
                <h2>school gallery</h2>
              </div>
      </div>
            <div className="col-lg-12">
              
              <Gallery />
              <button className='button'><Link to="/gallery">Read More</Link></button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  )
}

export default Home
