import React from 'react'
import { NavLink } from 'react-router-dom' 
import Highlights from'../Component/Highlights' 
const Header = () => {
  return (
        <>
        
        <div className="header padded-sec">
       
        <div className="logo">
            <NavLink to="/"><img src="/Images/logo.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid"/></NavLink>
        </div>
        <div className="header-nav">
        <Highlights/>
            <nav className="navbar navbar-expand-lg">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">
                    <li className="nav-item active"> <NavLink  className="dropdown-item" to="/">Home</NavLink></li>
                     <li className="nav-item dropdown">
                     
                       <div className="nav-link"  data-bs-toggle="dropdown">  About </div> 
                          <ul className="dropdown-menu fade-up"> 
                         <li><NavLink  className="dropdown-item" to="/about">Introduction</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/aims">Aims & Objective</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/motto">Our Motto</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="https://cisce.org/" target='_blank'>CISCE Website</NavLink></li> 
                        </ul>  
                    </li> 
                    <li className="nav-item dropdown">
                      <div className="nav-link" data-bs-toggle="dropdown">Academics </div>  
                         <ul className="dropdown-menu fade-up">
                            <li><NavLink  className="dropdown-item" to="/infrastructure">Infrastructure </NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/result">Results</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/outFit">Out-Fit</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/feestructure">Fee Structure</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/coCurricular">Co-Curricular Activities </NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/examinationAndPromotion">Examination & Promotion </NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/rules">General Rules</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/attendance">Attendance & Leave</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/calender">Academic Calendar</NavLink></li>
                        </ul> 
                    </li>
                    <li className="nav-item dropdown">
                       <div className="nav-link"  data-bs-toggle="dropdown"> Prospectus </div>  
                          <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/registration">Registration</NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/requirement">Requirement for Registration </NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/admission">Admission in Other Class</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/withdrawal">Admission & Withdrawals</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/recommendation">Recommendation to Parents</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/instruction">Instruction to Students</NavLink></li> 
                        </ul>  
                    </li>
                    <li className="nav-item dropdown"> 
                      <div className="nav-link"  data-bs-toggle="dropdown"> School Online</div>  
                           <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/ERPSoftware">ERP Software </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/ParentPortal">Portal (Help?)</NavLink></li> 
                        </ul>  
                    </li>  
                    <li className="nav-item dropdown"> 
                      <div className="nav-link"  data-bs-toggle="dropdown">Download</div>  
                           <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/pdf/Medical-Certificate.pdf" target="_blank">Medical Certificate</NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/timetable">Time Table of ICSE & ISC</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/Tc">TC</NavLink></li> 
                        </ul>  
                    </li>  
                    <li className="nav-item dropdown"> 
                      <div className="nav-link"  data-bs-toggle="dropdown">Contact</div>  
                           <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/contact">Contact Info</NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/career">Career</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/">Site Map</NavLink></li> 
                        </ul>  
                    </li>  
                    <li className="nav-item"><NavLink className="dropdown-item" to="/gallery"> Gallery </NavLink></li> 
                </ul>
                </div> 
           </nav>
        </div>
        
    </div>
    </>
  )
}

export default Header
