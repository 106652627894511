import React, { useState } from 'react';

import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import { Link, useNavigate } from 'react-router-dom';
import { postTransferCertificate } from '../Service/Api';

const Tc = () => {
  const navigate = useNavigate();
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !admission) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        console.log(response.data);
        // Potentially navigate or show success message
        fetch(`https://d280nq1n4mqyso.cloudfront.net/api/image/${response.data}`)
          .then(response => response.blob())  // Assuming the data is in a blob format; adjust if different
          .then(blob => {
            // Create a link element, use it to download the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'tc.jpg';  // Set the file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('Your download has started.');  // Optional: Notify the user
          })
          .catch(() => alert('Failed to download data.'));  // Error handling
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setError("Failed to process your request. Please try again later.");
    }
  };

  return (
    <>
      <Header />
      <Breadcrumb title="Transfer Certificate" pageName="Transfer Certificate" mid="Download" />
      <div className="innerSection">
        <div className='container'>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
       
                            <h3 className='inner_title'>Transfer Certificate</h3> 
                     
              <form onSubmit={handleSubmit} className="tc" data-aos="fade-left" data-aos-duration="2000">
                <div className="text_box_sec"> <input id="name" value={name} onChange={handleFullNameChange} type="text" placeholder="Student Name" /></div>
                <div className="text_box_sec"> <input id="admission" value={admission} onChange={handleAdmissionNoChange} type="text" placeholder="Admission Number" /></div>
                <div className="text_box_sec"><input type="submit" value="SUBMIT" /></div>
                {error && <p className="error">{error}</p>}
              </form>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <img src="/Images/TC-format.jpg" className='img-fluid' />
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Tc;
