
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import React, { useState } from "react";
import { getHeader } from "../config"
import { form } from "../Service/Api"; 
const Contact = () => {
    
   const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",   
  });

  const handleChange = (e) => {
     setFormData({
        ...formData,
        [e.target.name]: e.target.value
     });
  };

  const handleSubmit = async (e) => {
     e.preventDefault();
  
     try {

        const type = 'dif'; 
        const { header } = getHeader(); // Retrieve headers from getHeader function
        const response = await form(formData, header, type); // Pass the headers to the form function
        console.log('Form submitted successfully', response);
        document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
        document.querySelectorAll(".input, textarea").forEach(input => {
           input.value = "";
       });
     } catch (error) {
        console.error('Error submitting form:', error); 
     }
  };
    return (
        <>
            <Header />
            <Breadcrumb title="Career" pageName="Career" mid="Contact" />
            <div className="innerSection career">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className='inner_title'>Career</h3>
                        </div>
                        <div className='col-lg-6'>
                           <img src="/Images/career.jpg" className='img-fluid border-img'></img>
                        </div>
                        <div className='col-lg-6'>
                            <div className="formboxright">
<h3 className='sub_title'>Application Form</h3>
                                <div className="primary-alert"></div>
                                <form onSubmit={handleSubmit}>
                                    <div className="formdiv">
                                        <div className="form-group">
                                            <label>Name<span>*</span></label>
                                            <input type="text" onChange={handleChange} value={formData.name} name="name" className="form-control input" required />
                                        </div>
                                        <div className="form-group">
                                            <label>Email<span>*</span></label>
                                            <input type="email" onChange={handleChange} value={formData.email} name="email" className="form-control input" required />
                                        </div>
                                        <div className="form-group">
                                            <label>Phone No.<span>*</span></label>
                                            <input type="text" onChange={handleChange} value={formData.phone} name="phone" className="form-control input" required />
                                        </div>
                                        <div className="form-group">
                                            <label >Your Message<span>*</span></label>
                                            <textarea className="form-control" onChange={handleChange} value={formData.message} name="message" required></textarea>
                                        </div>
                                    </div>
                                    <input type="submit" value="Submit" />

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div> 
            <Footer />
        </>
    )
}
export default Contact