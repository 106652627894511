import { useEffect, useState } from "react";
import { getBirthdays } from "../Service/Api";
import Slider from "react-slick";
const Birthday = () => {
  const [data, setData] = useState([]); // State for today's birthdays
  // const [dataAll, setDataAll] = useState([]); // State for all birthdays this month

  useEffect(() => {
    const fetchData = async () => {
      const birthdayData = await getBirthdays();
      setData(birthdayData);
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchDataAll = async () => {
  //     const birthdayDataAll = await getBirthdaysAll();
  //     const filterData = birthdayDataAll.filter((item) => {
  //       const month = new Date(item.date).getMonth();
  //       const currentMonth = new Date().getMonth();
  //       const getThisMonth = month === currentMonth
  //       return getThisMonth;
  //     })
  //     setDataAll(filterData);
  //   };
  //   fetchDataAll();
  // }, []);



  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="row">
        <Slider {...settings}>
          {data.length > 0 ? data.map((item, index) => (
            <div className="item" key={index}>
              <div className="topperdiv birthday">
                <div className="toppertaj">
                  {item.attachments.length > 0 ? (<img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid" alt="Little Flower School, Gita Vatika" />):(<img alt="Little Flower School, Gita Vatika" src="/Images/dummy.png" className="img-fluid" />)}
                </div>
                <div className="topperdesc">
                  <p className="name">{item.name}</p>
                  <p className="per">{item.class}</p>
                </div>
              </div>
            </div>
          )) : (
          <div className="item">
          <div className="topperdiv birthday">
            <div className="toppertaj">
              <img src="/Images/birthday.jpg" className="img-fluid" alt="Little Flower School, Gita Vatika" />
            </div>

            <div className="topperdesc">
              <p className="name">Student Name</p>
              <p className="per">Class</p>
            </div>
          </div>
        </div>
        )}

          {/* <div className="item">
            <div className="topperdiv birthday">
              <div className="toppertaj">
                <img src="/Images/birthday.jpg" className="img-fluid" />
              </div>

              <div className="topperdesc">
                <p className="name">Sneha Sharma</p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="topperdiv birthday">
              <div className="toppertaj">
                <img src="/Images/birthday.jpg" className="img-fluid" />
              </div>

              <div className="topperdesc">
                <p className="name">Sneha Sharma</p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="topperdiv birthday">
              <div className="toppertaj">
                <img src="/Images/birthday.jpg" className="img-fluid" />
              </div>

              <div className="topperdesc">
                <p className="name">Sneha Sharma</p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div> */}

        </Slider>
      </div>
    </>
  )
}

export default Birthday