
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Admission = () => {
    return(
        <>
        <Header />
        <Breadcrumb title="Admission" pageName="Admission" mid="Prospectus" />
        <div className="innerSection">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className='inner_title'>Admission</h3>
                    </div>
                    <div className='col-lg-6'>
                        <h3 className='sub_title'>Requirements</h3>
                        <ul>
                            <li>Birth Certificate as in the case of LKG and UKG; to classes I and above, Transfer Certificate from the school last attended.</li>
                            <li>Application for classes above VI will be entertained if the pupil is from a recognized English Medium school. The Transfer Certificate in that case must be countersigned by the competent authority.</li>
                            <li>A written Test and interview will be taken. Admission will be taken on the basis of merit.</li>
                            <li>Written Test in this case will be held in all subjects applicable for that class.</li>
                        </ul>
                    </div>
                    <div className='col-lg-6'>
                        <img src="/Images/admission.jpg" className='img-fluid border_img mt-0' alt="Little Flower School, Gita Vatika" />
                    </div>
                    <div className='col-md-12'>
                        <h3 className='sub_title'>Admission in Other Class</h3>
                        <ul>
                            <li>The list of the children selected for admission will be published on the School Notice Board.</li>
                            <li>The parent shall collect the fee pay-in-slip from the School Office and deposit the first installment of the fees at the time of admission.</li>
                            <li>Admission of the selected children shall be taken on the assigned days. Those who fail to respond shall forefeit their chance.</li>
                            <li>The following documents are essential at the time of admission:
                                <ol>
                                    <li>Transfer Certificate from the School last attended.</li>
                                    <li>Mark List (Report Card) in original.</li>
                                    <li>Medical Fitness Certificate: Proforma will be given from the school after the results are declared; the fitness must be certified by a physician in Govt. Service.</li>
                                    <li>Two Stamp-size recent photographs.</li>
                                </ol>
                            </li>
                            <li>The presence of the parents of the student to be admitted is compulsory to take admission. No representation will be accepted.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Admission