import React, { useState, useEffect } from 'react';

const Pageloader = () => {
    const [isVisible, setIsVisible] = useState(true);

    const closeLoader = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");
        let w, h;
        const particles = [];
        const probability = 0.04;
        let xPoint, yPoint;

        const resizeCanvas = () => {
            if (!!canvas) {
                w = canvas.width = window.innerWidth;
                h = canvas.height = window.innerHeight;
            }
        };

        const updateWorld = () => {
            update();
            paint();
            window.requestAnimationFrame(updateWorld);
        };

        const update = () => {
            if (particles.length < 500 && Math.random() < probability) {
                createFirework();
            }
            const alive = [];
            for (let i = 0; i < particles.length; i++) {
                if (particles[i].move()) {
                    alive.push(particles[i]);
                }
            }
            particles.length = 0; // Clear the array
            particles.push(...alive);
        };

        const paint = () => {
            ctx.globalCompositeOperation = 'source-over';
            ctx.fillStyle = "rgba(0,0,0,0.2)";
            ctx.fillRect(0, 0, w, h);
            ctx.globalCompositeOperation = 'lighter';
            for (let i = 0; i < particles.length; i++) {
                particles[i].draw(ctx);
            }
        };

        const createFirework = () => {
            xPoint = Math.random() * (w - 200) + 100;
            yPoint = Math.random() * (h - 200) + 100;
            const nFire = Math.random() * 50 + 100;
            const c = `rgb(${Math.floor(Math.random() * 200 + 55)},${Math.floor(Math.random() * 200 + 55)},${Math.floor(Math.random() * 200 + 55)})`;
            for (let i = 0; i < nFire; i++) {
                const particle = new Particle();
                particle.color = c;
                const vy = Math.sqrt(25 - particle.vx * particle.vx);
                if (Math.abs(particle.vy) > vy) {
                    particle.vy = particle.vy > 0 ? vy : -vy;
                }
                particles.push(particle);
            }
        };

        function Particle() {
            this.w = this.h = Math.random() * 4 + 1;
            this.x = xPoint - this.w / 2;
            this.y = yPoint - this.h / 2;
            this.vx = (Math.random() - 0.5) * 10;
            this.vy = (Math.random() - 0.5) * 10;
            this.alpha = Math.random() * .5 + .5;
            this.color = '';
        }

        Particle.prototype = {
            gravity: 0.05,
            move: function () {
                this.x += this.vx;
                this.vy += this.gravity;
                this.y += this.vy;
                this.alpha -= 0.01;
                if (this.x <= -this.w || this.x >= w ||
                        this.y >= h ||
                        this.alpha <= 0) {
                    return false;
                }
                return true;
            },
            draw: function (c) {
                c.save();
                c.beginPath();
                c.translate(this.x + this.w / 2, this.y + this.h / 2);
                c.arc(0, 0, this.w, 0, Math.PI * 2);
                c.fillStyle = this.color;
                c.globalAlpha = this.alpha;
                c.closePath();
                c.fill();
                c.restore();
            }
        };

        window.addEventListener("resize", resizeCanvas, false);
        window.addEventListener("DOMContentLoaded", resizeCanvas, false);
        window.requestAnimationFrame(updateWorld);
        resizeCanvas(); // Initial call to set up the canvas size

        // Cleanup on component unmount
        return () => {
            window.removeEventListener("resize", resizeCanvas);
            window.cancelAnimationFrame(updateWorld);
        };
    }, []); // Empty dependency array ensures this effect runs once

    if (!isVisible) return null;

    return (
        <div className="mainsld-overlay">
            
            <div className="firework-animation">
                <canvas id="canvas"></canvas>
            </div>
            <div className="closeLoader" onClick={closeLoader}>
                <i className="bi bi-x"></i>
            </div>
            <div className="enter-sec">
                <div className="enter-sec-details">
                    <img src="/Images/40Years.png" className="img-responsive" alt="LFS- Gita Vatika" />
                    <img src="/Images/logo.png" className="img-responsive" alt="LFS- Gita Vatika" />
                </div>
            </div>
           
        </div>
    );
};

export default Pageloader;
