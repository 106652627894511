import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getNotification } from '../Service/Api';
const Popup = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const topperData = await getNotification();
        setData(topperData);
  
        // Trigger modal when data is loaded
        if (topperData.length > 0) {
          const modal = new window.bootstrap.Modal(document.getElementById('exampleModalToggle'));
          modal.show();
        }
      };
      fetchData();
    }, []);
  
    useEffect(() => {
      console.log("events_data", data[0]);
    }, [data]);
  return (
     <>
       {data?.length > 0 && (
     <div className="popup modal fade" id="exampleModalToggle" aria-labelledby="exampleModalToggleLabel" tabindex="-1" style={{display:"none"}} aria-hidden="true">
     <div className="modal-dialog modal-dialog-centered">
         <div className="modal-content">
             <div id="modalH" className="carousel slide" data-bs-ride="carousel">
                 <div className="carousel-inner">
                 {data.map((item, index) => (
                     <div  key={index} className={`carousel-item${index === 0 ? ' active' : ''}`}>
                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                         <div className="modal-body">
                             <div className="card modalcard">
                                 <div className="row">
                                     <div className="card-body">
                                          <h3>{item.title}</h3> 
                                         {item.image.length>0 ? (<img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image[0]}`} className="img-fluid" alt="Little flower school, Geeta vatika" />) : (<></>)}
                                         <p>{item.description} </p> 
                                         <div className="clearfix"></div>
                                        <div className="modal-bottom"> {item.attachments && item.attachments.length > 0 &&(<Link target="_blank" to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="btn btn-primary btn-sm">Attachment</Link>)} {item.url && item.url.length > 0 &&(<Link target="_blank" to={item?.url} className="btn btn-primary btn-sm">Click Here</Link>)} </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                     ))}
                     {/* <div className="carousel-item">
                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                         <div className="modal-body">
                             <div className="card modalcard">
                                 <div className="row">
                                     <div className="card-body"> <h1>  Stay Tunned for More Updates  </h1>  <img src="/Images/modal1.jpg" className="img-fluid" alt="Gandhi High School, Sidhi - Madhya Pradesh" />
                                         <div className="clearfix"></div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div> */}
                 </div>
                
                 <button className="carousel-control-prev" type="button" data-bs-target="#modalH" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                 <button className="carousel-control-next" type="button" data-bs-target="#modalH" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
             </div>
         </div>
     </div>
 </div>
   )}
     </>
  )
}

export default Popup
