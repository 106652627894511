import React from 'react'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="campuslogo">
         <Link to="https://www.lfscampuscare.org/" target="_blank"><img src="/Images/Campulogo.png" className="img-fluid float-end campus" alt="Gandhi High School, Sidhi - Madhya Pradesh"/></Link>
      </div>
      </>
        
  )
}

export default Campuscare
