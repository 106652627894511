
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const FeeStructure = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Fee structure" pageName="Fee structure" mid="Academics" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className='inner_title'>Fee Structure</h3> 
                        </div>
                        <div className='col-lg-12'>
                            <iframe src="/pdf/fee-structure.pdf" height="800" width="100%"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default FeeStructure