import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getGallery } from '../Service/Api';
import Slider from "react-slick";
const Gallery = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getGallery();
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data[0]);
  }, [data]);

  const emptyArray = [
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "Images/gallery-pic.jpg", description: "Stay tuned for latest updates" },
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "Images/gallery-pic.jpg", description: "Stay tuned for latest updates" },
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "Images/gallery-pic.jpg", description: "Stay tuned for latest updates" },
  ];

  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="row">
        <Slider {...settings}>
          {data?.length > 0 ? data?.filter((div) => div.category === "PhotoGallery").map((item, index) => (
            <div className="item" key={index}>
              <div className="galleryimg">
                <div className="galleryimgbox">
                  <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="img-fluid" alt="Little Flower School" />
                  <div className="gallerydesc">
                    <Link to={`/SubGallery?id=${item._id}`} className=""><i className="bi bi-zoom-in"></i></Link>
                    <h6>{item.title}</h6>
                  </div>
                </div>
              </div>
            </div>
          )) : (
            <div className="item">
              <div className="galleryimg">
                <div className="galleryimgbox">
                  <img src="/Images/gallery1.png" className="img-fluid" alt="Little Flower School" />
                  <div className="gallerydesc">
                    <Link to="#" className=""><i className="bi bi-zoom-in"></i></Link>
                    <h6>Gallery Title</h6>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="/Images/gallery1.png" className="img-fluid" alt="Little Flower School"/>
                <div className="gallerydesc">
                    <Link to="#" className=""><i className="bi bi-zoom-in"></i></Link>
                    <h6>Gallery Title</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="/Images/gallery3.png" className="img-fluid" alt="Little Flower School"/>
                <div className="gallerydesc">
                    <Link to="#" className=""><i className="bi bi-zoom-in"></i></Link>
                    <h6>Gallery Title</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="/Images/gallery4.png" className="img-fluid" alt="Little Flower School"/>
                <div className="gallerydesc">
                    <Link to="#" className=""><i className="bi bi-zoom-in"></i></Link>
                    <h6>Gallery Title</h6> 
                </div>
            </div>
          </div>
        </div> */}
        </Slider>
      </div>
    </>
  )
}

export default Gallery